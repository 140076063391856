import { createContext, useState, useEffect } from "react";
import en from "../localization/locales/en.json";
import ru from "../localization/locales/ru.json";
import ge from "../localization/locales/ge.json";
import tr from "../localization/locales/tr.json";
import ar from "../localization/locales/ar.json";

const languageSet = {
    en,
    ru,
    ge,
    tr,
    ar
};

export const LocalizationContext = createContext({
    translations: en,
    switchLanguage: () => {},
    locale: 'en'
});

export const LocalizationProvider = ({ children }) => {
    const [locale, setLocale] = useState(localStorage.getItem('locale') || 'en');
    const [translations, setTranslations] = useState(languageSet[locale]);

    useEffect(() => {
        // Dynamically set translations based on locale
        setTranslations(languageSet[locale] || en);
    }, [locale]);

    const switchLanguage = (language) => {
        setLocale(language);
        localStorage.setItem('locale', language);
    }

    return (
        <LocalizationContext.Provider value={{translations, switchLanguage, locale}}>
            {children}
        </LocalizationContext.Provider>
    )
};