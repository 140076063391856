import { useEffect, useContext } from 'react';
import './InteractiveMapWidget.css';
import mapWidgetImage from '../img/Map1111.png';
import partnersimg from '../img/rb_2033.png';
import { LocalizationContext } from '../../Services/localization/LocalizationContext';

const InteractiveMapWidget = ({ activeTab, setActiveTab }) => {
    const { translations } = useContext(LocalizationContext);

    useEffect(() => {
        console.log(`Active tab changed to: ${activeTab}`);
    }, [activeTab]);

    const renderContent = () => {
        switch (activeTab) {
            case 'map':
                return (
                    <div className="content">
                        <div className="text-content-container">

                            <h3>We have released our NEW engine WeeeMap 3D 1.113 </h3>
                            <h6>Now booking Cars on the map of Georgia and the world has become even easier and more
                                convenient, literally in 3 clicks!</h6>

                        </div>
                        <div className="img-container1">

                            <img src={mapWidgetImage} alt="Map Widget" className="map-image"/>

                        </div>


                    </div>
                );
            case 'features':
                return <div className="content">
                    <div className="text-content-container">

                        <h3>How to become our partner?</h3>
                        <h6>
                            Each of our partners on Weeekend.me undergoes thorough moderation so that we can guarantee our clients only proven and reliable services. We value your time and are confident that effective cooperation is the key to success.

                            If you are confident in the quality of your services and want to become part of our network, please fill out the form on the website or contact us to discuss possible partnership terms.
                        </h6>


                    </div>
                    <div className="img-container1">

                        <img src={partnersimg} alt="Map Widget" className="map-image1"/>

                    </div>

                </div>;
            case 'social':
                return <div className="content">{translations.interactiveMapWidget.socialContent}</div>;
            case 'team':
                return <div className="content">{translations.interactiveMapWidget.teamContent}</div>;
            default:
                return <div className="content">{translations.interactiveMapWidget.mapContent}</div>;
        }
    };

    return (
        <div className="interactive-map-widget">
            <div className="tabs">
                <button
                    className={`tab ${activeTab === 'map' ? 'active' : ''}`}
                    onClick={() => setActiveTab('map')}
                >
                    {translations.interactiveMapWidget.map}
                </button>
                <button
                    className={`tab ${activeTab === 'features' ? 'active' : ''}`}
                    onClick={() => setActiveTab('features')}
                >
                    {translations.interactiveMapWidget.features}
                </button>
                {/*<button*/}
                {/*    className={`tab ${activeTab === 'social' ? 'active' : ''}`}*/}
                {/*    onClick={() => setActiveTab('social')}*/}
                {/*>*/}
                {/*    {translations.interactiveMapWidget.social}*/}
                {/*</button>*/}
                <button
                    className={`tab ${activeTab === 'team' ? 'active' : ''}`}
                    onClick={() => setActiveTab('team')}
                >
                    {translations.interactiveMapWidget.team}
                </button>
            </div>
            <div className="tab-content">
                {renderContent()}
            </div>
        </div>
    );
};

export default InteractiveMapWidget;
