export const categoryIcon = `
    <svg class="nav-icon-svg" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
      <g clip-path="url(#clip0_1_2765)">
        <path d="M16.6667 0H9.375C4.19792 0 0 4.19792 0 9.375V16.6667C0 18.9688 1.86458 20.8333 4.16667 20.8333H16.6667C18.9688 20.8333 20.8333 18.9688 20.8333 16.6667V4.16667C20.8333 1.86458 18.9688 0 16.6667 0ZM29.1667 20.8333H41.6667C43.9688 20.8333 45.8333 18.9688 45.8333 16.6667V9.375C45.8333 4.19792 41.6354 0 36.4583 0H29.1667C26.8646 0 25 1.86458 25 4.16667V16.6667C25 18.9688 26.8646 20.8333 29.1667 20.8333ZM16.6667 25H4.16667C1.86458 25 0 26.8646 0 29.1667V36.4583C0 41.6354 4.19792 45.8333 9.375 45.8333H16.6667C18.9688 45.8333 20.8333 43.9688 20.8333 41.6667V29.1667C20.8333 26.8646 18.9688 25 16.6667 25ZM49.3896 46.4437L44.0979 41.1521C45.1896 39.5042 45.8333 37.5354 45.8333 35.4167C45.8333 29.6729 41.1604 25 35.4167 25C29.6729 25 25 29.6729 25 35.4167C25 41.1604 29.6729 45.8333 35.4167 45.8333C37.5354 45.8333 39.5042 45.1896 41.1521 44.0979L46.4437 49.3896C46.85 49.7958 47.3833 50 47.9167 50C48.45 50 48.9833 49.7958 49.3896 49.3896C50.2042 48.575 50.2042 47.2583 49.3896 46.4437Z"/>
      </g>
      <defs>
        <clipPath id="clip0_1_2765">
          <rect width="50" height="50"/>
        </clipPath>
      </defs>
    </svg>
`;