import React, { useState, useContext } from "react";
import { LocalizationContext } from "../../Services/localization/LocalizationContext";

function ImageUpload({ onSingleImageChange, onMultipleImagesChange }) {
    const { translations } = useContext(LocalizationContext);
    const [singleImagePreview, setSingleImagePreview] = useState(null);
    const [multipleImagesPreviews, setMultipleImagesPreviews] = useState([]);

    const handleSingleImageChange = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSingleImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
            onSingleImageChange(file);
        }
    };

    const handleMultipleImagesChange = (files) => {
        Array.from(files).forEach(file => {
            const reader = new FileReader();
            reader.onloadend = () => {
                setMultipleImagesPreviews(prevPreviews => [...prevPreviews, reader.result]);
            };
            reader.readAsDataURL(file);
        });
        onMultipleImagesChange(files);
    };

    return (
        <div className="disFlex centring">
            <div>
                <label>{translations.imageUpload.uploadMainPhoto}</label>
                <input
                    type="file"
                    onChange={(e) => handleSingleImageChange(e.target.files[0])}
                />
                {singleImagePreview && (
                    <div>
                        <p>{translations.imageUpload.singleImagePreview}</p>
                        <img
                            src={singleImagePreview}
                            alt="Single Preview"
                            style={{ width: "50px", height: "auto" }}
                        />
                    </div>
                )}
            </div>

            <div>
                <label>{translations.imageUpload.uploadOtherPhotos}</label>
                <input
                    type="file"
                    multiple
                    onChange={(e) => handleMultipleImagesChange(e.target.files)}
                />
                {multipleImagesPreviews.length > 0 && (
                    <div>
                        <p>{translations.imageUpload.multipleImagesPreview}</p>
                        <div style={{ display: "flex", gap: "10px" }}>
                            {multipleImagesPreviews.map((preview, index) => (
                                <img
                                    key={index}
                                    src={preview}
                                    alt={`Preview ${index + 1}`}
                                    style={{ width: "100px", height: "auto" }}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ImageUpload;
