import React, { useState, useEffect, useContext } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db } from '../../firebase';
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';
import './AddCars.css';
import ImageUpload from "../ImageUpload/ImageUpload";
import AddMap from "../AddMap/AddMap";
import { getAuth } from "firebase/auth";
import { LocalizationContext } from '../../Services/localization/LocalizationContext';

const AddCars = () => {
    const { translations } = useContext(LocalizationContext); // Access translations
    const [company, setCompany] = useState('');
    const [city, setCity] = useState(translations.addCars.cityPlaceholder);
    const [brand, setBrand] = useState('');
    const [model, setModel] = useState('');
    const [description, setDescription] = useState('');
    const [carClass, setCarClass] = useState('');
    const [ac, setAc] = useState(translations.addCars.acPlaceholder);
    const [transmission, setTransmission] = useState(translations.addCars.transmissionPlaceholder);
    const [volume, setVolume] = useState('');
    const [fuelConsumption, setFuelConsumption] = useState('');
    const [bluetooth, setBluetooth] = useState(translations.addCars.bluetooth);
    const [usb, setUsb] = useState('USB');
    const [aux, setAux] = useState('AUX');
    const [fuel, setFuel] = useState(translations.addCars.fuelPlaceholder);
    const [year, setYear] = useState(translations.addCars.yearPlaceholder);
    const [price1day, setPrice1day] = useState('');
    const [price2to3days, setPrice2to3days] = useState('');
    const [price4to7days, setPrice4to7days] = useState('');
    const [price8to15days, setPrice8to15days] = useState('');
    const [price16to30days, setPrice16to30days] = useState('');
    const [price31days, setPrice31days] = useState('');
    const [singleImage, setSingleImage] = useState(null);
    const [multipleImages, setMultipleImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mapCoordinates, setMapCoordinates] = useState(null);
    const [deposit, setDeposit] = useState('');

    useEffect(() => {
        const savedCoordinates = localStorage.getItem('mapCoordinates');
        if (savedCoordinates) {
            setMapCoordinates(JSON.parse(savedCoordinates));
        }
    }, []);

    const uploadImage = (image) => {
        return new Promise((resolve, reject) => {
            const storageRef = ref(storage, `images/${image.name}`);
            const uploadTask = uploadBytesResumable(storageRef, image);

            uploadTask.on(
                "state_changed",
                null,
                (error) => reject(error),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(resolve).catch(reject);
                }
            );
        });
    };

    const handleSubmit = async () => {
        setLoading(true);

        try {
            let imageUrl = "";
            if (singleImage) {
                imageUrl = await uploadImage(singleImage);
            }

            const imageUrls = [];
            if (multipleImages.length > 0) {
                for (const image of multipleImages) {
                    const url = await uploadImage(image);
                    imageUrls.push(url);
                }
            }

            const auth = getAuth();
            const user = auth.currentUser;
            if (!user) {
                throw new Error("User not logged in");
            }

            const userDocRef = doc(db, 'users', user.uid);
            const userDoc = await getDoc(userDocRef);
            const userLogin = userDoc.data()?.login || 'Unknown User';

            const carsCollectionRef = collection(db, 'cars');
            await addDoc(carsCollectionRef, {
                company,
                city,
                brand,
                model,
                description,
                ac,
                transmission,
                volume,
                carClass,
                fuelConsumption,
                bluetooth,
                usb,
                aux,
                fuel,
                year,
                price1day,
                price2to3days,
                price4to7days,
                price8to15days,
                price16to30days,
                price31days,
                imageUrl,
                imageUrls,
                deposit,
                location: mapCoordinates,
                status: "moderation",
                login: userLogin,
            });

            // Reset fields after success
            setCompany('');
            setCity(translations.addCars.cityPlaceholder);
            setBrand('');
            setModel('');
            setDescription('');
            setCarClass('');
            setAc(translations.addCars.acPlaceholder);
            setTransmission(translations.addCars.transmissionPlaceholder);
            setVolume('');
            setFuelConsumption('');
            setBluetooth(translations.addCars.bluetooth);
            setUsb('USB');
            setAux('AUX');
            setFuel(translations.addCars.fuelPlaceholder);
            setYear(translations.addCars.yearPlaceholder);
            setPrice1day('');
            setPrice2to3days('');
            setPrice4to7days('');
            setPrice8to15days('');
            setPrice16to30days('');
            setPrice31days('');
            setSingleImage(null);
            setMultipleImages([]);
            setMapCoordinates(null);
            setDeposit('')

            localStorage.removeItem('mapCoordinates');
        } catch (e) {
            console.error("Error adding document: ", e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={'addCarsContainer'}>
            <h2>Добавить автомобиль</h2>

            {/* Название компании */}
            <div className="input-container">
                <label htmlFor="company" className="name-add-text">Название вашей компании <span
                    className="required">*</span>
                </label>
                <input
                    type="text"
                    id="company"
                    placeholder={translations.addCars.company}
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                />

            </div>

            {/* Город нахождения автомобиля */}
            <div className="input-container">

                <label htmlFor="city" className="name-add-text">Выберите город, где находится автомобиль:</label>
                <select
                    id="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                >
                    <option disabled>{translations.addCars.cityPlaceholder}</option>
                    <option value="Tbilisi">{translations.addCars.cityTbilisi}</option>
                    <option value="Batumi">{translations.addCars.cityBatumi}</option>
                    <option value="Kutaisi">Kutaisi</option>
                </select>

            </div>

            {/* Марка и модель автомобиля */}
            <div className="input-container">
                <label htmlFor="brand" className="name-add-text">Выберите марку и модель автомобиля:</label>
                <select
                    id="brand"
                    value={brand}
                    onChange={(e) => setBrand(e.target.value)}
                >
                    <option disabled value="">Марка</option>
                    {['Toyota', 'Honda', 'Ford', 'Chevrolet', 'BMW', 'Mercedes-Benz', 'Audi', 'Volkswagen', 'Nissan', 'Hyundai', 'Kia', 'Lexus', 'Subaru', 'Mazda', 'Tesla', 'Jaguar', 'Land Rover', 'Volvo', 'Porsche', 'Ferrari', 'Lamborghini', 'Mitsubishi', 'Peugeot', 'Renault', 'Fiat', 'Citroën'].map((brandOption) => (
                        <option key={brandOption} value={brandOption}>{brandOption}</option>
                    ))}
                </select>

                <input
                    type="text"
                    id="model"
                    placeholder="Модель"
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                />


            </div>
            <label htmlFor="description" className="name-add-text">Класс автомобиля:</label>
            <select
                id="carClass"
                value={carClass}
                onChange={(e) => setCarClass(e.target.value)}
            >
                <option disabled value="Класс">Выберите класс автомобиля</option>
                {['Седан', 'Внедорожник', 'Минивэн', 'Купе', 'Кабриолет', 'Хэтчбек', 'Универсал', 'Пикап', 'Лимузин', 'Кроссовер', 'Фургон', 'Родстер', 'Спортивный', 'Гибрид', 'Электромобиль', 'Микроавтобус'].map((carClassOption) => (
                    <option key={carClassOption} value={carClassOption}>{carClassOption}</option>
                ))}
            </select>

            {/* Топливо и год */}
            <div className="input-container">
                <label htmlFor="fuel" className="name-add-text">Тип топлива:</label>
                <select
                    id="fuel"
                    value={fuel}
                    onChange={(e) => setFuel(e.target.value)}
                >
                    <option>{translations.editCarModal.fuelGasoline}</option>
                    <option>{translations.editCarModal.fuelPropane}</option>
                    <option>{translations.editCarModal.fuelMethane}</option>
                    <option>{translations.editCarModal.fuelHybridGas}</option>
                    <option>{translations.editCarModal.fuelHybridDiesel}</option>
                    <option>{translations.editCarModal.fuelElectric}</option>
                </select>
                <label htmlFor="year" className="name-add-text">Год выпуска автомобиля:</label>
                <input
                    type="text"
                    id="year"
                    placeholder="Год"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                />


            </div>
            {/* Объём и расход */}
            <div className="input-container">
                <label htmlFor="volume" className="name-add-text">Объём двигателя:</label>
                <input
                    type="text"
                    id="volume"
                    placeholder="Объём"
                    value={volume}
                    onChange={(e) => setVolume(e.target.value)}
                />
                <label htmlFor="fuelConsumption" className="name-add-text">Расход топлива (л/100 км):</label>
                <input
                    type="text"
                    id="fuelConsumption"
                    placeholder="Расход/100 км"
                    value={fuelConsumption}
                    onChange={(e) => setFuelConsumption(e.target.value)}
                />

            </div>


            {/* Трансмиссия */}
            <div className="input-container">

                <label htmlFor="transmission" className="name-add-text">Трансмиссия:</label>
                <select
                    id="transmission"
                    value={transmission}
                    onChange={(e) => setTransmission(e.target.value)}
                >
                    <option disabled>Трансмиссия</option>
                    <option>Automatic</option>
                    <option>Manual</option>
                    <option>CVT</option>
                </select>

            </div>


            {/* Описание и класс автомобиля */}
            <div className="input-container">
                <label htmlFor="description" className="name-add-text">Описание автомобиля:</label>
                <input
                    type="textarea"
                    id="description"
                    placeholder="Описание"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />

            </div>


            {/* Bluetooth, USB, AUX */}
            <label className="name-add-text">Дополнительные опции автомобиля:</label>
            <div className="input-container-checkbox">

                <label>
                    <input
                        id="ac"
                        value={ac}
                        type="checkbox"
                        checked={ac === "Есть"}
                        onChange={(e) => setAc(e.target.checked ? "Есть" : "Нет")}
                    />
                    AirConditioner
                </label>


                <label>
                    <input
                        type="checkbox"
                        checked={bluetooth === "Есть"}
                        onChange={(e) => setBluetooth(e.target.checked ? "Есть" : "Нет")}
                    />
                    Bluetooth
                </label>


                <label>
                    <input
                        type="checkbox"
                        checked={usb === "Есть"}
                        onChange={(e) => setUsb(e.target.checked ? "Есть" : "Нет")}
                    />
                    USB
                </label>

                <label>
                    <input
                        type="checkbox"
                        checked={aux === "Есть"}
                        onChange={(e) => setAux(e.target.checked ? "Есть" : "Нет")}
                    />
                    AUX
                </label>

            </div>


            {/* Цены по дням */}
            <div className="input-container">
                <label htmlFor="price1day" className="name-add-text">Цена за 1 день:</label>
                <input
                    type="text"
                    id="price1day"
                    placeholder="1 день"
                    value={price1day}
                    onChange={(e) => setPrice1day(e.target.value)}
                />
                <label htmlFor="price2to3days" className="name-add-text">Цена за 2-3 дня:</label>
                <input
                    type="text"
                    id="price2to3days"
                    placeholder="2-3 дня"
                    value={price2to3days}
                    onChange={(e) => setPrice2to3days(e.target.value)}
                />

            </div>

            {/* Продолжение цен */}
            <div className="input-container">
                <label htmlFor="price4to7days" className="name-add-text">Цена за 4-7 дней:</label>
                <input
                    type="text"
                    id="price4to7days"
                    placeholder="4-7 дней"
                    value={price4to7days}
                    onChange={(e) => setPrice4to7days(e.target.value)}
                />
                <label htmlFor="price8to15days" className="name-add-text">Цена за 8-15 дней:</label>
                <input
                    type="text"
                    id="price8to15days"
                    placeholder="8-15 дней"
                    value={price8to15days}
                    onChange={(e) => setPrice8to15days(e.target.value)}
                />

            </div>

            {/* Цена за более длительные сроки */}
            <div className="input-container">
                <label htmlFor="price16to30days" className="name-add-text">Цена за 16-30 дней:</label>
                <input
                    type="text"
                    id="price16to30days"
                    placeholder="16-30 дней"
                    value={price16to30days}
                    onChange={(e) => setPrice16to30days(e.target.value)}
                />
                <label htmlFor="price31days" className="name-add-text">Стоимость аренды за 1 месяц:</label>
                <input
                    type="text"
                    id="price31days"
                    placeholder="31 день"
                    value={price31days}
                    onChange={(e) => setPrice31days(e.target.value)}
                />


                <div className="input-container">
                    <label htmlFor="deposit" className="name-add-text">Укажите депозит при необходимости</label>
                    <input
                        type="text"
                        placeholder={translations.editCarModal.depositPlaceholder}
                        name="deposit"
                        value={deposit}
                        onChange={(e) => setDeposit(e.target.value)}
                    />
                </div>

            </div>


            <ImageUpload
                onSingleImageChange={setSingleImage}
                onMultipleImagesChange={(files) => setMultipleImages(Array.from(files))}
            />
            <div>
                <AddMap onMapDataChange={setMapCoordinates}></AddMap>
                <button
                    onClick={handleSubmit}
                    disabled={loading}
                    style={{width: '100%'}}
                >
                    {loading ? translations.addCars.loading : translations.addCars.submit}
                </button>
            </div>
        </div>
    );
};

export default AddCars;
