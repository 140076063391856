import { useEffect, useState, useContext } from 'react';
import { db, collection, getDocs } from '../../firebase';
import { CategoryContext } from '../../App';

const usePopups = () => {
    const { activeCategories } = useContext(CategoryContext);
    const [popups, setPopups] = useState([]);

    useEffect(() => {
        console.log('Active Categories:', activeCategories);

        const fetchPopups = async () => {
            console.log('Fetching popups from Firebase for categories:', activeCategories);
            let popupData = [];

            if (activeCategories.includes('cars')) {
                const querySnapshot = await getDocs(collection(db, 'cars'));

                popupData = querySnapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }))
                    .filter(car => {
                        const location = car.location || {};
                        return car.status === 'approved' && (location.lng && location.lat);
                    }) // Фильтруем по статусу
            }
            console.log('Fetched popups:', popupData);

            setPopups((prevPopups) => {
                if (JSON.stringify(prevPopups) === JSON.stringify(popupData)) {
                    console.log('Popups have not changed, skipping update.');
                    return prevPopups;
                }
                return popupData;
            });
        };
        fetchPopups();
    }, [activeCategories]);

    return popups;
};

export default usePopups;
