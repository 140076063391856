import React, { useState, useEffect, useContext } from 'react';
import { doc, updateDoc, getFirestore } from 'firebase/firestore';
import ImageUpload from "../ImageUpload/ImageUpload";
import AddMap from "../AddMap/AddMap";

import './EditCarModal.css';
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../firebase";
import ChangeImage from "./ChangeImage";
import MultiplieChangeImage from "./MultiplieChangeImage";
import { LocalizationContext } from '../../Services/localization/LocalizationContext';

const EditCarModal = ({ car, isOpen, onClose, reloadCars }) => {
    const { translations } = useContext(LocalizationContext);
    const [carData, setCarData] = useState(car);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setCarData(car);
    }, [car]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCarData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCarData(prevData => ({
            ...prevData,
            [name]: checked ? "Есть" : "Нет"
        }));
    };

    // Функция для загрузки файлов в Firebase Storage
    const uploadFile = (file) => {
        return new Promise((resolve, reject) => {
            const storageRef = ref(storage, `images/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    // Прогресс загрузки (можно добавить индикатор загрузки)
                },
                (error) => {
                    console.error("Ошибка загрузки файла: ", error);
                    reject(error);
                },
                () => {
                    // Получаем URL после успешной загрузки
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        resolve(downloadURL);
                    }).catch(reject);
                }
            );
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const firestore = getFirestore();
        const carDocRef = doc(firestore, 'cars', carData.id);

        try {
            let updatedCarData = { ...carData };

            // Загрузка одного изображения, если оно есть
            if (carData.singleImage) {
                const singleImageUrl = await uploadFile(carData.singleImage);
                updatedCarData.singleImageUrl = singleImageUrl; // Обновляем URL изображения
            }

            // Загрузка нескольких изображений, если они есть
            if (carData.multipleImages && carData.multipleImages.length > 0) {
                const multipleImagesUrls = await Promise.all(
                    carData.multipleImages.map(file => uploadFile(file))
                );
                updatedCarData.multipleImagesUrls = multipleImagesUrls; // Обновляем URL-ы изображений
            }

            delete updatedCarData.singleImage;
            delete updatedCarData.multipleImages;

            updatedCarData.status = 'moderation'; // Устанавливаем статус "moderation"

            // Обновляем документ автомобиля в Firestore
            await updateDoc(carDocRef, updatedCarData);
            await reloadCars(); // Перезагружаем список автомобилей

            onClose(updatedCarData); // Закрываем модальное окно и передаем обновленные данные

        } catch (error) {
            console.error("Ошибка при обновлении автомобиля: ", error);
        } finally {
            setLoading(false);
        }
    };



    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className={'close-buttonWrapper'} style={{width: '100%'}}>
                    <button className={'close-button'} onClick={(event) => onClose()}>×</button>
                </div>
                <h2>{translations.editCarModal.editCarTitle}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="disFlex">
                        <input
                            type="text"
                            placeholder={translations.editCarModal.companyPlaceholder}
                            name="company"
                            value={carData.company || ''}
                            onChange={handleChange}
                        />
                        <select
                            name="city"
                            value={carData.city || ''}
                            onChange={handleChange}
                        >
                            <option disabled>{translations.editCarModal.cityPlaceholder}</option>
                            <option>{translations.editCarModal.tbilisi}</option>
                            <option>{translations.editCarModal.batumi}</option>
                        </select>
                    </div>

                    <div className="disFlex">
                        <select
                            name="brand"
                            value={carData.brand || ''}
                            onChange={handleChange}
                        >
                            <option disabled value="">{translations.editCarModal.selectBrand}</option>
                            <option value="Toyota">Toyota</option>
                            <option value="Honda">Honda</option>
                            <option value="Ford">Ford</option>
                            <option value="Chevrolet">Chevrolet</option>
                            <option value="BMW">BMW</option>
                            <option value="Mercedes-Benz">Mercedes-Benz</option>
                            <option value="Audi">Audi</option>
                            <option value="Volkswagen">Volkswagen</option>
                            <option value="Nissan">Nissan</option>
                            <option value="Hyundai">Hyundai</option>
                            <option value="Kia">Kia</option>
                            <option value="Lexus">Lexus</option>
                            <option value="Subaru">Subaru</option>
                            <option value="Mazda">Mazda</option>
                            <option value="Tesla">Tesla</option>
                            <option value="Jaguar">Jaguar</option>
                            <option value="Land Rover">Land Rover</option>
                            <option value="Volvo">Volvo</option>
                            <option value="Porsche">Porsche</option>
                            <option value="Ferrari">Ferrari</option>
                            <option value="Lamborghini">Lamborghini</option>
                            <option value="Mitsubishi">Mitsubishi</option>
                            <option value="Peugeot">Peugeot</option>
                            <option value="Renault">Renault</option>
                            <option value="Fiat">Fiat</option>
                            <option value="Citroën">Citroën</option>
                        </select>
                        <input
                            type="text"
                            placeholder={translations.editCarModal.modelPlaceholder}
                            name="model"
                            value={carData.model || ''}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="disFlex">
                         <textarea
                             placeholder={translations.editCarModal.descriptionPlaceholder}
                             name="description"
                             value={carData.description || ''}
                             onChange={handleChange}
                         />
                        <select
                            name="carClass"
                            value={carData.carClass || ''}
                            onChange={handleChange}
                        >
                            <option disabled value="">{translations.editCarModal.selectCarClass}</option>
                            <option value="Седан">{translations.editCarModal.carClassSedan}</option>
                            <option value="Внедорожник">{translations.editCarModal.carClassSuv}</option>
                            <option value="Минивэн">{translations.editCarModal.carClassMinivan}</option>
                            <option value="Купе">{translations.editCarModal.carClassCoupe}</option>
                            <option value="Кабриолет">{translations.editCarModal.carClassConvertible}</option>
                            <option value="Хэтчбек">{translations.editCarModal.carClassHatchback}</option>
                            <option value="Универсал">{translations.editCarModal.carClassStationWagon}</option>
                            <option value="Пикап">{translations.editCarModal.carClassPickup}</option>
                            <option value="Лимузин">{translations.editCarModal.carClassLimousine}</option>
                            <option value="Кроссовер">{translations.editCarModal.carClassCrossover}</option>
                            <option value="Фургон">{translations.editCarModal.carClassVan}</option>
                            <option value="Родстер">{translations.editCarModal.carClassRoadster}</option>
                            <option value="Спортивный">{translations.editCarModal.carClassSportsCar}</option>
                            <option value="Гибрид">{translations.editCarModal.carClassHybrid}</option>
                            <option value="Электромобиль">{translations.editCarModal.carClassElectric}</option>
                            <option value="Микроавтобус">{translations.editCarModal.carClassMicrobus}</option>
                        </select>
                    </div>

                    <div className="disFlex">
                        <select
                            name="ac"
                            value={carData.ac || ''}
                            onChange={handleChange}
                        >
                            <option disabled>{translations.editCarModal.acPlaceholder}</option>
                            <option>{translations.editCarModal.yes}</option>
                            <option>{translations.editCarModal.no}</option>
                        </select>
                        <select
                            name="transmission"
                            value={carData.transmission || ''}
                            onChange={handleChange}
                        >
                            <option disabled>{translations.editCarModal.transmissionPlaceholder}</option>
                            <option>Automatic</option>
                            <option>Manual</option>
                            <option>CVT</option>
                        </select>
                    </div>
                    <div className={'disFlex'}>
                        <select
                            name="fuel"
                            value={carData.fuel || ''}
                            onChange={handleChange}
                        >
                        <option disabled>{translations.editCarModal.fuelPlaceholder}</option>
                            <option>{translations.editCarModal.fuelGasoline}</option>
                            <option>{translations.editCarModal.fuelPropane}</option>
                            <option>{translations.editCarModal.fuelMethane}</option>
                            <option>{translations.editCarModal.fuelHybridGas}</option>
                            <option>{translations.editCarModal.fuelHybridDiesel}</option>
                            <option>{translations.editCarModal.fuelElectric}</option>
                        </select>
                    </div>
                    <div className="disFlex">
                        <input
                            type="text"
                            placeholder={translations.editCarModal.volumePlaceholder}
                            name="volume"
                            value={carData.volume || ''}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            placeholder={translations.editCarModal.fuelConsumptionPlaceholder}
                            name="fuelConsumption"
                            value={carData.fuelConsumption || ''}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="disFlex">
                        <label>
                            <input
                                type="checkbox"
                                name="bluetooth"
                                checked={carData.bluetooth === "Есть"}
                                onChange={handleCheckboxChange}
                            />
                            Bluetooth
                        </label>

                        <label>
                            <input
                                type="checkbox"
                                name="usb"
                                checked={carData.usb === "Есть"}
                                onChange={handleCheckboxChange}
                            />
                            USB
                        </label>

                        <label>
                            <input
                                type="checkbox"
                                name="aux"
                                checked={carData.aux === "Есть"}
                                onChange={handleCheckboxChange}
                            />
                            AUX
                        </label>
                    </div>

                    <div className="disFlex">
                        <input
                            type="text"
                            placeholder={translations.editCarModal.price1dayPlaceholder}
                            name="price1day"
                            value={carData.price1day || ''}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            placeholder={translations.editCarModal.price2to3daysPlaceholder}
                            name="price2to3days"
                            value={carData.price2to3days || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="disFlex">
                        <input
                            type="text"
                            placeholder={translations.editCarModal.price4to7daysPlaceholder}
                            name="price4to7days"
                            value={carData.price4to7days || ''}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            placeholder={translations.editCarModal.price8to15daysPlaceholder}
                            name="price8to15days"
                            value={carData.price8to15days || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="disFlex">
                        <input
                            type="text"
                            placeholder={translations.editCarModal.price16to30daysPlaceholder}
                            name="price16to30days"
                            value={carData.price16to30days || ''}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            placeholder={translations.editCarModal.price31daysPlaceholder}
                            name="price31days"
                            value={carData.price31days || ''}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="disFlex">
                        <input
                            type="text"
                            placeholder={translations.editCarModal.depositPlaceholder}
                            name="deposit"
                            value={carData.deposit || ''}
                            onChange={handleChange}
                        />
                    </div>

                    <ChangeImage
                        carId={carData.id} // Передаем ID автомобиля
                        currentImageUrl={carData.imageUrl} // Передаем текущий URL изображения
                        onImageUrlChange={(newUrl) => setCarData(prevData => ({
                            ...prevData,
                            imageUrl: newUrl
                        }))} // Обновляем URL изображения в данных автомобиля
                    />
                    <MultiplieChangeImage
                        carId={carData.id} // Передаем ID автомобиля
                        currentImageUrls={carData.imageUrls || []} // Передаем текущие URL изображений
                        onImageUrlsChange={(newUrls) => setCarData(prevData => ({
                            ...prevData,
                            imageUrls: newUrls
                        }))} // Обновляем URL-ы изображений в данных автомобиля
                    />


                    {/*<ImageUpload*/}
                    {/*    onSingleImageChange={(image) => setCarData(prevData => ({ ...prevData, singleImage: image }))}*/}
                    {/*    onMultipleImagesChange={(files) => setCarData(prevData => ({ ...prevData, multipleImages: Array.from(files) }))}*/}
                    {/*/>*/}
                    <AddMap
                        onMapDataChange={(coordinates) => setCarData(prevData => ({
                            ...prevData,
                            mapCoordinates: coordinates
                        }))}
                    />

                    <button type="submit" disabled={loading} className={'saveEdit'}>
                        {loading ? translations.editCarModal.loading : translations.editCarModal.saveButton}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default EditCarModal;
