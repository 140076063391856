import React, { useState, useEffect, useContext} from 'react';
import './Crm.css';
import { useNavigate } from 'react-router-dom';
import { auth, db, storage } from "../../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import hatchback from './CrmImages/hatchback.png';
import stamp from './CrmImages/stamp.png';
import mountain from './CrmImages/mountain.png';
import house from './CrmImages/house.png';
import boat from './CrmImages/boat.png';
import eye from './CrmImages/eye.png';
import decline from './CrmImages/decline.png';
import switchOff from './CrmImages/switchOff.png';
import switchOn from './CrmImages/switchOn.png';
import AddCars from "../AddCars/AddCars";
import ProductListCar from "./ProductLists/ProductListCar";
import { LocalizationContext } from '../../Services/localization/LocalizationContext';

const Crm = () => {
    const { translations } = useContext(LocalizationContext);
    const [activeState, setActiveState] = useState('moderation');
    const [currentUser, setCurrentUser] = useState(null); // Initialize user state
    const navigate = useNavigate();
    const [showAddCars, setShowAddCars] = useState(false);

    // Fetch the current user's status
    useEffect(() => {
        const fetchUserStatus = async () => {
            if (auth.currentUser) {
                const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
                if (userDoc.exists()) {
                    setCurrentUser(userDoc.data());
                }
            }
        };
        fetchUserStatus();
    }, []);

    const handleIconClick = (state) => {
        setActiveState(state);
    };
    const toggleAddCars = () => {
        setShowAddCars(prevState => !prevState);
    };
    const isAdmin = currentUser?.role === 'admin';

    return (
        <div>
              {/* Кнопка назад */}

            <div className={'CrmContainer'}>
                <div className={'button-back-container'}>
                    <button className="back-button" onClick={() => navigate(-1)}>
                        {translations.supportPage.backSupportButton}
                    </button>
                </div>

                <div className={'name-text-Crm'}>
                    <h2>ADD OBJECT</h2>
                    <h4>Выберите категорию для загрузки на Weeekend:</h4>
                </div>



                <div className={'IconFlex'}>
                    <div className={'DisGrid'}>
                        <img
                            className={'crmIcon'}
                            src={hatchback}
                            alt="Hatchback"
                            onClick={toggleAddCars} />
                        {translations.crm.cars}

                    </div>
                    <div className={'DisGrid'}>
                        <img className={'crmIcon'} src={house} alt="House"/>
                        {translations.crm.property}
                    </div>
                    <div className={'DisGrid'}>
                        <img className={'crmIcon'} src={boat} alt="Boat"/>
                        {translations.crm.yachts}
                    </div>
                    <div className={'DisGrid'}>
                        <img className={'crmIcon'} src={mountain} alt="Mountain"/>
                        {translations.crm.tours}
                    </div>

                </div>
                {showAddCars && <AddCars />}

                <div className={'name-text-Crm'}>
                    <h2>CRM SYSTEM</h2>
                    <h4>Отслеживайте статус своих объектов на платформе:</h4>
                </div>
                <div className={'IconFlex'}>
                {!isAdmin && (
                        <>
                            <div className={'DisGrid'}>
                                <img
                                    className={`crmIcon ${activeState === 'approved' ? 'active' : ''}`}
                                    src={switchOn}
                                    alt="Approved"
                                    onClick={() => handleIconClick('approved')}
                                />
                                {translations.crm.active}
                            </div>
                            <div className={'DisGrid'}>
                                <img
                                    className={`crmIcon ${activeState === 'declined' ? 'active' : ''}`}
                                    src={decline}
                                    alt="Decline"
                                    onClick={() => handleIconClick('declined')}
                                />
                                {translations.crm.declined}
                            </div>
                            <div className={'DisGrid'}>
                                <img
                                    className={`crmIcon ${activeState === 'off' ? 'active' : ''}`}
                                    src={switchOff}
                                    alt="SwitchOff"
                                    onClick={() => handleIconClick('off')}
                                />
                                {translations.crm.off}
                            </div>
                        </>
                    )}
                    <div className={'DisGrid'}>
                        <img
                            className={`crmIcon ${activeState === 'moderation' ? 'active' : ''}`}
                            src={eye}
                            alt="Moderation"
                            onClick={() => handleIconClick('moderation')}
                        />
                        {translations.crm.moderation}
                    </div>
                </div>
                <ProductListCar activeState={activeState} role={currentUser?.role}/>
            </div>
        </div>
    );
};

export default Crm;