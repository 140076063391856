import { useEffect, useState } from 'react';
import './ThemeToggle.css';

const ThemeToggle = () => {
    const [theme, setTheme] = useState('day-mode');

    // Check for saved theme in localStorage on component mount
    useEffect(() => {
        const savedTheme = localStorage.getItem('theme') || 'day-mode';
        setTheme(savedTheme);
        document.body.classList.add(savedTheme);
    }, []);

    // Function to toggle theme
    const toggleTheme = () => {
        const newTheme = theme === 'night-mode' ? 'day-mode' : 'night-mode';

        // Remove previous theme and add new theme class
        document.body.classList.remove(theme);
        document.body.classList.add(newTheme);

        // Save new theme in localStorage
        localStorage.setItem('theme', newTheme);
        setTheme(newTheme);

        const themeChangeEvent = new CustomEvent('themeChange', { detail: newTheme });
        window.dispatchEvent(themeChangeEvent);
    };

    return (
        <button onClick={toggleTheme} className="theme-toggle-btn">
            <span className="theme-toggle-span"></span>
        </button>
    );
};

export default ThemeToggle;
