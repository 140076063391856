import { useState, useEffect } from 'react';
import { db, doc, setDoc, collection, getDocs } from '../../firebase';
import './ModerationPage.css';

const ModerationPage = () => {
    const [partnerRequests, setPartnerRequests] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRequests = async () => {
            setLoading(true);
            const querySnapshot = await getDocs(collection(db, "users"));
            const requests = querySnapshot.docs
                .map(doc => ({ id: doc.id, ...doc.data() }))
                .filter(user => user.status === "pending"); // Filter pending users
            setPartnerRequests(requests);
            setLoading(false);
        };

        fetchRequests();
    }, []);

    const approvePartner = async (partnerId) => {
        try {
            const partnerRef = doc(db, "users", partnerId);

            await setDoc(partnerRef, { role: "partner", status: "approved" }, { merge: true });
            console.log("Partner approved successfully");

            setPartnerRequests(prevRequests => prevRequests.filter(request => request.id !== partnerId));
        } catch (error) {
            console.error("Error approving partner: ", error);
        }
    };

    const declinePartner = async (requestId) => {
        try {
            const userRef = doc(db, "users", requestId);
            await setDoc(userRef, { status: "declined" }, { merge: true });

            setPartnerRequests(prev => prev.filter(request => request.id !== requestId));
        } catch (error) {
            console.error("Error declining partner:", error);
        }
    };

    if (loading) {
        return <p>Loading partner requests...</p>;
    }

    return (
        <div className="moderation-page">
            <h2>Partner Requests</h2>
            {partnerRequests.length === 0 ? (
                <p>No partner requests to review</p>
            ) : (
                partnerRequests.map(request => (
                    <div key={request.id} className="partner-request-card">
                        <h3>{request.name}</h3>
                        <p><strong>Phone:</strong> {request.phone}</p>
                        <p><strong>Description:</strong> {request.description}</p>
                        <button onClick={() => approvePartner(request.id)}>Approve</button>
                        <button onClick={() => declinePartner(request.id)}>Decline</button>
                    </div>
                ))
            )}
        </div>
    );
};

export default ModerationPage;
