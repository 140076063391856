import React, { useState, useEffect } from 'react';
import { getMetadata, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { doc, updateDoc, getFirestore, deleteField } from 'firebase/firestore';
import { storage } from '../../firebase';

// Функция для извлечения пути к файлу из полного URL
const extractFilePathFromUrl = (url) => {
    const match = url.match(/\/o\/(.*?)\?alt=media/);
    return match ? decodeURIComponent(match[1]) : null;
};

const MultiplieChangeImage = ({ carId, currentImageUrls = [], onImageUrlsChange }) => {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [imageUrls, setImageUrls] = useState(currentImageUrls); // Начальные URL из Firestore

    // Проверяем наличие carId перед обращением к Firestore
    if (!carId) {
        console.error('carId is undefined');
        return null;
    }

    const firestore = getFirestore();
    const carDocRef = doc(firestore, 'cars', carId);

    // Функция для загрузки файлов в Firebase Storage
    const uploadFile = (file) => {
        return new Promise((resolve, reject) => {
            const storageRef = ref(storage, `images/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    // Прогресс загрузки можно добавить индикатор прогресса
                },
                (error) => {
                    console.error('Ошибка загрузки файла: ', error);
                    reject(error);
                },
                () => {
                    // Получаем URL после успешной загрузки
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        resolve(downloadURL);
                    }).catch(reject);
                }
            );
        });
    };

    const handleFileChange = (e) => {
        setFiles(Array.from(e.target.files)); // Сохраняем файлы как массив
    };

    const handleUpload = async () => {
        if (files.length === 0) return;

        setLoading(true);

        try {
            const newImageUrls = await Promise.all(files.map(file => uploadFile(file))); // Загружаем все файлы

            // Обновляем массив URL-ов (новые + старые)
            const updatedImageUrls = [...imageUrls, ...newImageUrls];
            setImageUrls(updatedImageUrls); // Локально обновляем URL-ы

            // Обновляем Firestore с новым массивом URL-ов
            await updateDoc(carDocRef, {
                imageUrls: updatedImageUrls, // Обновляем поле imageUrls в Firestore
            });

            // Передаем новый массив URL родительскому компоненту
            if (onImageUrlsChange) {
                onImageUrlsChange(updatedImageUrls);
            }

        } catch (error) {
            console.error('Ошибка при загрузке изображений: ', error);
        } finally {
            setLoading(false);
        }
    };

    // Функция для проверки существования изображений
    const checkImagesExist = async () => {
        const validUrls = [];
        const invalidUrls = [];

        for (const url of imageUrls) {
            const filePath = extractFilePathFromUrl(url);
            const storageRef = ref(storage, filePath);

            try {
                await getMetadata(storageRef); // Проверяем наличие объекта
                validUrls.push(url); // Если объект существует, сохраняем URL
            } catch (error) {
                if (error.code === 'storage/object-not-found') {
                    invalidUrls.push(url); // Если объект не найден, сохраняем URL для удаления
                }
            }
        }

        // Если есть невалидные URL, обновляем Firestore
        if (invalidUrls.length > 0) {
            const updatedImageUrls = validUrls;
            setImageUrls(updatedImageUrls);

            // Обновляем Firestore, удаляя невалидные URL
            await updateDoc(carDocRef, {
                imageUrls: updatedImageUrls.length > 0 ? updatedImageUrls : deleteField(), // Удаляем поле imageUrls, если список пустой
            });

            // Передаем обновленный массив URL родительскому компоненту
            if (onImageUrlsChange) {
                onImageUrlsChange(updatedImageUrls);
            }
        }
    };

    // Функция для удаления изображения из Firebase Storage и Firestore
    const handleDeleteImage = async (url) => {
        const filePath = extractFilePathFromUrl(url);
        const storageRef = ref(storage, filePath);

        // Удаляем изображение из Storage
        await deleteObject(storageRef);

        // Обновляем массив URL в Firestore
        const updatedImageUrls = imageUrls.filter((imageUrl) => imageUrl !== url);
        setImageUrls(updatedImageUrls);

        // Если все изображения удалены, удаляем поле imageUrls
        await updateDoc(carDocRef, {
            imageUrls: updatedImageUrls.length > 0 ? updatedImageUrls : deleteField(),
        });

        // Передаем обновленный массив URL родительскому компоненту
        if (onImageUrlsChange) {
            onImageUrlsChange(updatedImageUrls);
        }
    };

    // Эффект для проверки изображений при загрузке компонента
    useEffect(() => {
        checkImagesExist();
    }, []);

    return (
        <div className="multiple-image-upload">
            <input type="file" multiple onChange={handleFileChange} />
            <button onClick={handleUpload} disabled={loading}>
                {loading ? 'Загрузка...' : 'Загрузить изображения'}
            </button>
            {imageUrls.length > 0 && (
                <div className="image-preview">
                    {imageUrls.map((url, index) => (
                        <div key={index} className="image-item" style={{ position: 'relative' }}>
                            <img src={url} alt={`Car ${index}`} style={{ maxWidth: '100px', margin: '5px' }} />
                            <button
                                className="delete-button"
                                onClick={() => handleDeleteImage(url)}
                                style={{ position: 'absolute', top: '5px', right: '5px', cursor: 'pointer' }}
                            >
                                ×
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MultiplieChangeImage;
