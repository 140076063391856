import React, { useState ,useEffect} from 'react';
import './SideFilters.css';
import brandlogo from './ProductListCarsImg/brands.svg'
import filterimg from './ProductListCarsImg/Filtersimg.svg'
import geoloc from './ProductListCarsImg/geoloc.svg'

import audi from './CarsLogos/Brands Car/Audi Car Logo - 640x479.png'
import bmw from './CarsLogos/Brands Car/Bmw Car Logo - 480x480.png'
import chevrolet from './CarsLogos/Brands Car/Chevrolet Car Logo - 640x243.png'
import ferrari from './CarsLogos/Brands Car/Ferrari Logo - 480x640.png'
import ford from './CarsLogos/Brands Car/Ford Car Logo - 640x246.png'
import honda from './CarsLogos/Brands Car/Honda Car Logo - 640x522.png'
import hyundai from './CarsLogos/Brands Car/Hyundai Logo - 640x334.png'
import jaguar from './CarsLogos/Brands Car/Jaguar Car Logo - 640x480.png'
import kia from './CarsLogos/Brands Car/Kia  Car Logo - 640x322.png'
import lamborghini from './CarsLogos/Brands Car/Lamborghini Logo - 480x549.png'
import landrover from './CarsLogos/Brands Car/Land Rover Logo - 640x480.png'
import lexus from './CarsLogos/Brands Car/Lexus Car Logo - 640x320.png'
import mazda from './CarsLogos/Brands Car/Mazda Logo - 640x592.png'
import nissan from './CarsLogos/Brands Car/Nissan Car Logo - 640x548.png'
import porshe from './CarsLogos/Brands Car/Porsche Car Logo - 480x627.png'
import subaru from './CarsLogos/Brands Car/Subaru Car Logo - 640x384.png'
import tesla from './CarsLogos/Brands Car/Tesla-removebg.png'
import toyota from './CarsLogos/Brands Car/Toyota Car Logo - 640x534.png'
import volkswagen from './CarsLogos/Brands Car/Volkswagen Car Logo - 640x639.png'
import volvo from './CarsLogos/Brands Car/Volvo Car Logo - 640x537.png'
import mercedes from './CarsLogos/Brands Car/Mercedes Benz Car Logo - 640x480.png'


const SideFilters = ({ onFilter }) => {
    const [brands, setBrands] = useState([]); // Для хранения выбранных брендов
    const [priceSort, setPriceSort] = useState(''); // Для хранения значения сортировки
    const [transmission, setTransmission] = useState([]); // Изначально пустой массив
    const [fuel, setFuel] = useState([]);
    const [company, setCompany] = useState(''); // Для фильтрации по компании
    const [showBrands, setShowBrands] = useState(false); // Для отображения или скрытия списка брендов
    const [showFilters, setShowFilters] = useState(false); // Управление видимостью фильтров
    const [showPickupCities, setShowPickupCities] = useState(false); // Управление видимостью селекта для pick-up
    const [showPickoffCities, setShowPickoffCities] = useState(false); // Управление видимостью селекта для pick-off
    const [pickupCity, setPickupCity] = useState('');
    const [pickoffCity, setPickoffCity] = useState('');


    const allBrands = [
        //
        { name: "BMW", logo: bmw },
        { name: "Mercedes-Benz", logo: mercedes },
        { name: "Porsche", logo: porshe },
        { name: "Ford", logo: ford },
        { name: "Chevrolet", logo: chevrolet },


        { name: "Kia", logo: kia },
        { name: "Audi", logo: audi },
        { name: "Volkswagen", logo: volkswagen },
        { name: "Nissan", logo: nissan },
        //cadilac
        //lincoln
         { name: "Mazda", logo: mazda },
        { name: "Hyundai", logo: hyundai },
        { name: "Honda", logo: honda },
        { name: "Lexus", logo: lexus },
        { name: "Subaru", logo: subaru },

        { name: "Tesla", logo: tesla },
        { name: "Jaguar", logo: jaguar },
        { name: "Land Rover", logo: landrover },
        { name: "Volvo", logo: volvo },

        { name: "Ferrari", logo: ferrari },
        { name: "Lamborghini", logo: lamborghini },










    ];


    const cities = ["Batumi", "Tbilisi"];

    // Обрабатываем сортировку и другие фильтры
    const handleFilterChange = (newBrands, newPriceSort, newTransmission, newCompany, newFuel, newPickupCity) => {
        onFilter({
            brands: newBrands !== undefined ? newBrands : brands,
            priceSort: newPriceSort !== undefined ? newPriceSort : priceSort,
            transmission: newTransmission !== undefined ? newTransmission : transmission,
            fuel: newFuel !== undefined ? newFuel : fuel,
            company: newCompany !== undefined ? newCompany : company,
            pickupCity: newPickupCity !== undefined ? newPickupCity : pickupCity, // Changed city to pickupCity
        });
    };
    const CustomDropdown = ({ options, value, onChange, placeholder }) => {
        const [isOpen, setIsOpen] = useState(false);

        const handleSelect = (option) => {
            onChange(option);
            setIsOpen(false); // Закрываем список после выбора
        };

        return (
            <div className="dropdown-container">
                <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
                    <div>{value || placeholder}</div>
                    {/* Показываем placeholder, если значение не выбрано */}

                </div>
                {isOpen && (
                    <div className="dropdown-list">
                        {options.map((option, index) => (
                            <div
                                key={index}
                                className="dropdown-option"
                                onClick={() => handleSelect(option)}
                            >
                                {option}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };
    // Обработка выбора брендов (чекбоксы)
    const handleBrandChange = (brandName) => {
        const updatedBrands = brands.includes(brandName)
            ? brands.filter((brand) => brand !== brandName)
            : [...brands, brandName];

        setBrands(updatedBrands);
        handleFilterChange(updatedBrands, undefined, undefined, undefined);
    };

    const handlePickupCityChange = (city) => {
        setPickupCity(city);
        handleFilterChange(undefined, undefined, undefined, undefined); // Можно добавить логику фильтрации, если нужно
    };

    const handlePickoffCityChange = (e) => {
        setPickoffCity(e.target.value);
        // Логика фильтрации для города pick-off, если нужно
    };

    // Обработка изменений сортировки по цене
    const handlePriceSortChange = (sortType) => {
        setPriceSort(sortType);
        handleFilterChange(undefined, sortType, undefined, undefined);
    };

    // Обработка изменений трансмиссии
    const handleTransmissionChange = (value) => {
        // Проверяем, выбран ли уже этот тип трансмиссии
        const isSelected = transmission.includes(value);

        // Если уже выбран, убираем его из списка, если не выбран — добавляем
        const updatedTransmission = isSelected
            ? transmission.filter(item => item !== value) // Убираем
            : [...transmission, value]; // Добавляем

        // Обновляем состояние
        setTransmission(updatedTransmission);

        // Вызываем handleFilterChange с обновленным массивом трансмиссий
        handleFilterChange(undefined, undefined, updatedTransmission, undefined);
    };

    const handleFuelChange = (fuelType) => {
        const isSelected = fuel.includes(fuelType);

        const updatedFuel = isSelected
            ? fuel.filter(item => item !== fuelType) // Убираем топливо, если оно уже выбрано
            : [...fuel, fuelType]; // Добавляем топливо, если его еще нет

        setFuel(updatedFuel);
        handleFilterChange(undefined, undefined, undefined, undefined, updatedFuel); // Обновляем фильтр с новым массивом
    };

    // Обработка изменений компании
    const handleCompanyChange = (e) => {
        const newCompany = e.target.value;
        setCompany(newCompany);
        handleFilterChange(undefined, undefined, undefined, newCompany);
    };

    // Переключение отображения списка брендов
    const toggleBrandList = () => {
        setShowBrands(!showBrands);
    };

    // Переключение отображения всех фильтров
    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    // Переключение отображения селекта городов для pick-up и pick-off
    const togglePickupCities = () => {
        setShowPickupCities(!showPickupCities);
    };

    const togglePickoffCities = () => {
        setShowPickoffCities(!showPickoffCities);
    };

    return (
        <div className="filter-buttons">
            <div className="FilterWrap">

                <div className={'disFlex BrandWrapper'}>
                    <CustomDropdown
                        options={cities}
                        value={pickupCity}
                        onChange={handlePickupCityChange}
                        placeholder={(
                            <div className="placeholder-content">
                                <svg width="25" height="50" viewBox="0 0 25 50" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M14.5833 24.825C12.9166 25.0605 12.0043 25.0602 10.4167 24.825V47.9167C10.4167 49.0667 11.35 50 12.5 50C13.65 50 14.5833 49.0667 14.5833 47.9167V24.825Z"
                                          fill="currentColor"/>
                                    <path
                                        d="M25 12.5C25 5.60625 19.3937 0 12.5 0C5.60625 0 0 5.60625 0 12.5C0 18.6833 4.5125 23.8313 10.4167 24.825C12.0043 25.0602 12.9166 25.0605 14.5833 24.825C20.4875 23.8313 25 18.6833 25 12.5Z"
                                        fill="currentColor"/>
                                </svg>

                                <div className={'placeholder-info'}>
                                    <div className={'maintext'}>Pick-up</div>
                                    <div className={'secondarytext'}>Your location</div>
                                </div>
                            </div>
                        )}
                    />
                </div>
                <div className={'disFlex BrandWrapper priceFilters'}>
                    <div className={'pricefilters'}><p>Price:</p>
                        <div className={'disFlex centring'}><label
                            className={`priceLabel ${priceSort === 'high' ? 'active' : ''}`}
                        >
                            <input
                                type="checkbox"
                                checked={priceSort === 'high'}
                                onChange={() => handlePriceSortChange('high')}
                                className="hiddenCheckbox"
                            />
                            High
                        </label>

                            <label
                                className={`priceLabel ${priceSort === 'low' ? 'active' : ''}`}
                            >
                                <input
                                    type="checkbox"
                                    checked={priceSort === 'low'}
                                    onChange={() => handlePriceSortChange('low')}
                                    className="hiddenCheckbox"
                                />
                                Low
                            </label></div>
                    </div>
                </div>

                <div className={'disFlex BrandWrapper'} onClick={toggleBrandList} style={{cursor: 'pointer'}}>


                    <svg className={'FilterIcon'} width="26" height="26" viewBox="0 0 26 26" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13 0C9.55333 0.00373993 6.24889 1.37902 3.81173 3.8241C1.37456 6.26917 0.00372784 9.58433 0 13.0422C0.65 30.3231 25.35 30.3155 26 13.0422C25.9963 9.58433 24.6254 6.26917 22.1883 3.8241C19.7511 1.37902 16.4467 0.00373993 13 0ZM13 3.26055C14.9239 3.2624 16.8044 3.83448 18.4053 4.90495C20.0061 5.97542 21.2561 7.49655 21.9982 9.27734L15.1927 10.6435C13.6944 10.9435 12.1518 10.9435 10.6535 10.6435L3.98992 9.3056C4.72855 7.51773 5.97851 5.98944 7.58207 4.91358C9.18563 3.83772 11.0709 3.26251 13 3.26055ZM3.2565 13.3758L6.67333 14.0616C7.29704 14.1868 7.88564 14.4482 8.39733 14.8273C8.90903 15.2063 9.33127 15.6937 9.63408 16.2549L10.3112 17.508C10.653 18.1424 10.8324 18.852 10.8333 19.5731V22.576C8.73698 22.0933 6.85805 20.9299 5.48807 19.2664C4.11809 17.6029 3.33366 15.5323 3.2565 13.3758ZM15.1667 22.5804V19.5774C15.1676 18.8563 15.347 18.1467 15.6888 17.5124L16.3659 16.2593C16.6687 15.6981 17.091 15.2107 17.6027 14.8316C18.1144 14.4526 18.703 14.1912 19.3267 14.066L22.7435 13.3802C22.6663 15.5366 21.8819 17.6073 20.5119 19.2708C19.142 20.9343 17.263 22.0976 15.1667 22.5804Z"
                            fill="currentColor"/>
                    </svg>

                    <div className={'brnd'}>Brands</div>

                </div>


                <div className="toggle-filters-button" onClick={toggleFilters}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.08332 5.14663H4.04732C4.63232 7.29884 6.85142 8.56936 9.00384 7.98442C10.3863 7.60872 11.4662 6.52891 11.8419 5.14663H24.9167C25.515 5.14663 26 4.66167 26 4.06342C26 3.46518 25.515 2.98021 24.9167 2.98021H11.8419C11.2569 0.827957 9.03781 -0.442567 6.88539 0.142376C5.50297 0.51807 4.42305 1.59788 4.04732 2.98016H1.08332C0.485013 2.98016 0 3.46513 0 4.06337C0 4.66162 0.485013 5.14663 1.08332 5.14663Z"
                            fill="currentColor"/>
                        <path
                            d="M24.9167 11.9168H21.9527C21.3688 9.76488 19.151 8.49364 16.9989 9.07736C15.6153 9.45265 14.5345 10.5333 14.1592 11.9168H1.08332C0.485013 11.9168 0 12.4017 0 13C0 13.5982 0.485013 14.0832 1.08332 14.0832H14.1592C14.743 16.2351 16.9609 17.5063 19.113 16.9226C20.4965 16.5473 21.5773 15.4666 21.9527 14.0832H24.9167C25.515 14.0832 26 13.5982 26 13C26 12.4017 25.515 11.9168 24.9167 11.9168Z"
                            fill="currentColor"/>
                        <path
                            d="M24.9167 20.8532H11.8419C11.2569 18.701 9.03781 17.4305 6.88539 18.0154C5.50297 18.3911 4.42305 19.4709 4.04732 20.8532H1.08332C0.485013 20.8532 0 21.3382 0 21.9364C0 22.5347 0.485013 23.0196 1.08332 23.0196H4.04732C4.63232 25.1718 6.85142 26.4424 9.00384 25.8574C10.3863 25.4817 11.4662 24.4019 11.8419 23.0196H24.9167C25.515 23.0196 26 22.5347 26 21.9364C26 21.3382 25.515 20.8532 24.9167 20.8532Z"
                            fill="currentColor"/>
                    </svg>

                    <div>Filters</div>
                </div>

            </div>


            {showBrands && (
                <div className="brand-list">
                    {/*<div className="brands-info">*/}
                    {/*    <div className={'Brand-info-main'}>Brands</div>*/}
                    {/*    <div className={'Brand-info-default'}>Choose your</div>*/}
                    {/*    <div className={'Brand-info-default'}>favorite</div>*/}
                    {/*</div>*/}
                    {allBrands.map((brand) => (
                        <div key={brand.name} className="brand-item" onClick={() => handleBrandChange(brand.name)}>
                            <img
                                src={brand.logo}
                                alt={brand.name}
                                className={`brand-logo ${brands.includes(brand.name) ? 'selected' : ''}`}
                            />
                            <span>{brand.name}</span>
                        </div>
                    ))}
                </div>
            )}

            {showFilters && (
                <div className="filters-container">

                    <div className="filter-item">
                        <label>Partners</label>
                        <input
                            className={'partner-input'}
                            type="text"
                            placeholder="Name company"
                            value={company}
                            onChange={handleCompanyChange}
                        />
                    </div>
                    <div className="filter-item">
                        <label>Gear box</label>
                        <div className="child">

                            <div className={''}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={transmission.includes('Automatic')}
                                        onChange={() => handleTransmissionChange('Automatic')}
                                    />
                                    Automatic
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={transmission.includes('Manual')}
                                        onChange={() => handleTransmissionChange('Manual')}
                                    />
                                    Manual
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={transmission.includes('CVT')}
                                        onChange={() => handleTransmissionChange('CVT')}
                                    />
                                    CVT
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="filter-item">
                        <label>Engine</label>
                        <div className={'child'}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={fuel.includes('Diesel')}
                                    onChange={() => handleFuelChange('Diesel')}
                                />
                                Diesel
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={fuel.includes('Electric')}
                                    onChange={() => handleFuelChange('Electric')}
                                />
                                Electric
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={fuel.includes('Hybrid')}
                                    onChange={() => handleFuelChange('Hybrid')}
                                />
                                Hybrid
                            </label>
                        </div>

                    </div>




                </div>
            )}
        </div>
    );
};

export default SideFilters;
