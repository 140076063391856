import { useLocation, Link } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import './LoginForm.css';
import { LocalizationContext } from "../../Services/localization/LocalizationContext";
import { signInWithEmailAndPassword, GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../firebase";
import image1 from '../img/google-light.png';
import eyeOpen from '../img/eyeIconOpen.png'; // Открытый глаз (черно-белый)
import eyeClosed from '../img/eyeIconClosed.png'; // Закрытый глаз (черно-белый)
import lightThemeImage from '../img/apple-light.png'; // Изображение для светлой темы
import darkThemeImage from '../img/apple-dark.png';

const LoginForm = () => {
    const { translations } = useContext(LocalizationContext);
    const location = useLocation();
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'day-mode'); // Начальное значение темы

    const [formData, setFormData] = useState({
        identifier: '',
        password: '',
    });
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false); // состояние для переключения видимости пароля

    useEffect(() => {
        const handleThemeChange = (e) => {
            setTheme(e.detail);
        };

        window.addEventListener('themeChange', handleThemeChange);
        return () => {
            window.removeEventListener('themeChange', handleThemeChange);
        };
    }, []);

    
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage('');

        try {
            await signInWithEmailAndPassword(auth, formData.identifier, formData.password);
            console.log("User logged in successfully");
        } catch (error) {
            console.error("Login error: ", error.message);
            setErrorMessage(translations.loginForm.invalidCredentials || "Invalid credentials. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            await signInWithPopup(auth, provider);
            console.log("User logged in with Google successfully");
        } catch (error) {
            console.error("Google login error: ", error.message);
            setErrorMessage("Failed to login with Google. Please try again.");
        }
    };

    const handleAppleSignIn = async () => {
        const provider = new OAuthProvider('apple.com');
        try {
            await signInWithPopup(auth, provider);
            console.log("User logged in with Apple successfully");
        } catch (error) {
            console.error("Apple login error: ", error.message);
            setErrorMessage("Failed to login with Apple. Please try again.");
        }
    };

    return (
            <div className="login-form-container">
                <div className="login-form-header">
                    <h2>
                        <Link
                            to="/login"
                            className={location.pathname === "/login" ? "active-link" : ""}
                        >
                            {translations.loginForm.loginButton}
                        </Link>
                    </h2>
                    <h2>
                        <Link
                            to="/signup">{translations.loginForm.signUpHere}
                        </Link>
                    </h2>
                </div>

                <div className="image-form">
                    <div className="login-form-content-image">
                        <div className="login-form-image"></div>
                    </div>
                    <div className="login-form-content">
                        <div className="login-form-body">
                            <h1>{translations.loginForm.loginToYourAccount}</h1>
                        </div>
                        <div className="login-form-group">
                            <div className="login-image-container">
                                <img src={image1} alt="Google icon" className="login-image"
                                     onClick={handleGoogleSignIn}/>
                            </div>
                            <div className="login-image-container">
                                <img
                                    src={theme === 'night-mode' ? darkThemeImage : darkThemeImage}
                                    alt="Apple icon"
                                    className="login-image"
                                    onClick={handleAppleSignIn} // Добавлен обработчик клика
                                />
                            </div>
                        </div>
                        <div className="login-form-body">
                            <p>{translations.loginForm.emailOrPhoneOrLogin}</p>
                        </div>

                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="identifier"
                                    value={formData.identifier}
                                    onChange={handleInputChange}
                                    required
                                    placeholder={translations.loginForm.emailOrPhoneOrLogin}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type={showPassword ? "text" : "password"} // Переключение типа input между "text" и "password"
                                    id="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    required
                                    placeholder={translations.loginForm.password}
                                />
                                <span
                                    onClick={togglePasswordVisibility}
                                    className={`eye-icon ${showPassword ? "open" : "closed"}`} // Класс для иконки
                                >
                            <img
                                src={showPassword ? eyeOpen : eyeClosed} // Подставляем нужную иконку
                                alt={showPassword ? "Hide password" : "Show password"}
                                className="eye-icon-image"
                            />
                        </span>
                            </div>
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                            <button type="submit" className="submit-button" disabled={loading}>
                                {loading ? "Logging in..." : translations.loginForm.loginButton}
                            </button>
                        </form>
                    </div>
                </div>
            </div>

    );
};

export default LoginForm;
