import { useRef } from 'react';

export const useSwipeToScroll = () => {
    const touchStartXRef = useRef(0);
    const touchEndXRef = useRef(0);
    const touchStartYRef = useRef(0);
    const touchEndYRef = useRef(0);

    const handleTouchStart = (e) => {
        touchStartXRef.current = e.touches[0].clientX;
        touchStartYRef.current = e.touches[0].clientY;
    };

    const handleTouchMove = (e) => {
        touchEndXRef.current = e.touches[0].clientX;
        touchEndYRef.current = e.touches[0].clientY;
    };

    const handleTouchEnd = (scrollContainerRef) => {
        const swipeDistanceX = touchEndXRef.current - touchStartXRef.current;
        const swipeDistanceY = Math.abs(touchEndYRef.current - touchStartYRef.current);

        if (swipeDistanceY > Math.abs(swipeDistanceX)) return;

        if (Math.abs(swipeDistanceX) > 50) {
            scrollContainerRef.current.scrollBy({ left: swipeDistanceX > 0 ? -200 : 200, behavior: 'smooth' });
            console.log(swipeDistanceX > 0 ? "Swipe Left" : "Swipe Right");
        }

        touchStartXRef.current = 0;
        touchEndXRef.current = 0;
        touchStartYRef.current = 0;
        touchEndYRef.current = 0;
    };

    return { handleTouchStart, handleTouchMove, handleTouchEnd };
};
