import React, { useState, useEffect, useContext } from 'react';
import './Profile.css';
import { auth, db,  storage } from "../../firebase";
import { doc, getDoc, setDoc} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { UserContext } from '../../Services/context/UserContext';
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { LocalizationContext } from '../../Services/localization/LocalizationContext';
import PartnerForm from '../PartnerForm/PartnerForm';
import gearIcon from '../img/edit-but.png';
import crmIcon from '../img/crm.png';
import supportIcon from '../img/support.png';
import ordersIcon from '../img/orders.png';
import logoutIcon from '../img/logout.png';
import saveIcon from '../img/save.png';
import likeIcon from '../img/like.png';
import emailIcon from '../img/gmail.png';
import bioIcon from '../img/bio.png';
import surnameIcon from '../img/surname.png';
import downArrow from '../img/down-arrow.png';
import uploadIcon from '../img/uploadd.png';
import partnerIcon from '../img/partner.png';


const Profile = () => {
    const { userData, setUserData } = useContext(UserContext);
    const { translations } = useContext(LocalizationContext);
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [avatarFile, setAvatarFile] = useState(null);
    const [saving, setSaving] = useState(false);
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false); // Уведомление о сохранении
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalFormOpen, setIsModalFormOpen] = useState(false);
     const handleBecomePartner = () => {
        setIsModalFormOpen(true);
    };
    const handleButtonClick = () => {
        setIsModalOpen(true);
    };
    const closeFormModal = () => {
        setIsModalFormOpen(false);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const toggleInputs = () => {
        setIsExpanded((prev) => !prev);
    };
    useEffect(() => {
        console.log(translations.profilePage);
// Debugging to check if translations are loaded correctly
    }, [translations]);
    useEffect(() => {
        const fetchUserData = async () => {
            const currentUser = auth.currentUser;
            if (currentUser) {
                const docRef = doc(db, "users", currentUser.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setUserData(docSnap.data());
                } else {
                    console.log("No such document!");
                }
            }
            setLoading(false);
        };
        fetchUserData();
    }, []);
    const handleAddObjects = () => {
        navigate("/crm");
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleSave = async () => {
    setSaving(true);
    try {
        const currentUser = auth.currentUser;
        const docRef = doc(db, "users", currentUser.uid);
        let downloadURL = userData.avatar;
        if (avatarFile) {
            const storageRef = ref(storage, `avatars/${currentUser.uid}`);
            await uploadBytes(storageRef, avatarFile);
            downloadURL = await getDownloadURL(storageRef);
        }
        const updatedUserData = {
            ...userData,
            avatar: downloadURL,
        };
        await setDoc(docRef, updatedUserData);
        setUserData(updatedUserData);
        // Показываем уведомление
        setSaveSuccess(true);
        // Ждем 3 секунды, чтобы пользователь увидел уведомление
        setTimeout(() => {
            setSaveSuccess(false); // Убираем уведомление
            setIsEditing(false); // Выходим из режима редактирования
        }, 3000);
    } catch (error) {
        console.error("Error updating profile: ", error);
    }
    setSaving(false);
};
    const handleAvatarChange = async (e)     => {
    if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        setAvatarFile(file);
        setIsUploaded(true); // Показываем уведомление
        try {
            setSaving(true); // Устанавливаем состояние загрузки
            const currentUser = auth.currentUser;
            const storageRef = ref(storage, `avatars/${currentUser.uid}`);
            // Загружаем файл в хранилище
            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);
            // Обновляем Firestore с новой ссылкой на аватар
            const docRef = doc(db, "users", currentUser.uid);
            const updatedUserData = {
                ...userData,
                avatar: downloadURL,
            };
            await setDoc(docRef, updatedUserData);
            // Обновляем локальное состояние
            setUserData(updatedUserData);
            // Показываем уведомление
            setSaveSuccess(true);
            // Скрываем уведомление через 3 секунды
            setTimeout(() => {
                setSaveSuccess(false);
            }, 3000);
        } catch (error) {
            console.error("Error updating avatar: ", error);
        } finally {
            setSaving(false); // Завершаем состояние загрузки
        }
        // Опционально скрываем уведомление через 3 секунды
        setTimeout(() => setIsUploaded(false), 3000);
    }
};
    const handleLogout = async () => {
    const isConfirmed = window.confirm("Вы уверены, что хотите выйти из аккаунта?");
    if (isConfirmed) {
        try {
            await signOut(auth);
            setUserData({});
            console.log("User logged out");
            navigate("/login");
        } catch (error) {
            console.error("Error logging out: ", error);
        }
    }
};
    const profileTranslations = translations?.profilePage || {};
    const getAvatarLabel = () => (avatarFile ? avatarFile.name : profileTranslations.chooseFile || "Choose a file");
    const getSaveButtonLabel = () => (saving ? profileTranslations.saving || 'Saving...' : profileTranslations.saveProfile || 'Save Profile');
    if (loading) {
        return <p>{translations?.loading || 'Loading...'}</p>;
    }
      const handleBackClick = () => {
        navigate(-1);
    };
    return (
        <div className="profile-container">
            <div className="profile-grid">
                <div className="name-text">
                    {isEditing && ( // Show the back button only if we're in editing mode
                        <div className="back-button-container">
                            <button className="back-button-edit" onClick={() => setIsEditing(false)}>
                                <span>{translations.supportPage.backSupportButton}</span>
                            </button>
                        </div>
                    )}
                    <div className="name-text">
                        <h2>{userData.name}</h2>
                    </div>
                </div>

                <div className="profile-avatar">
                    <img src={userData.avatar || 'https://via.placeholder.com/150'} alt="Avatar"/>
                    <div className="profile-role">
                        <div className="profile-role-container">
                            <p>{profileTranslations.role || 'Role'}: {userData.role || profileTranslations.user || 'User'}</p>
                        </div>
                    </div>
                </div>



                <div className="profile-info">

                    {isEditing ? (
                        <>
                            <div className="profile-input-container">
                                <label htmlFor="avatar-upload" className="file-input-label">
                                    <img src={uploadIcon} alt="Upload" className="avatar-upload-icon"/>
                                    <span
                                        className="button-text">{profileTranslations.uploadAvatar || 'Upload avatar'}
                                    </span>
                                </label>
                                <input
                                    type="file"
                                    id="avatar-upload"
                                    className="file-input"
                                    onChange={handleAvatarChange}
                                    accept="image/*"
                                />
                            </div>
                            <div className="edit-container-button">
                                <div className="inputs-wrapper">
                                    <button className="toggle-button" onClick={toggleInputs}>
                                        <img
                                            src={downArrow}
                                            alt="Toggle"
                                            className={`arrow-icon ${isExpanded ? 'expanded' : 'collapsed'}`}
                                        />
                                        <span
                                            className="button-text">{profileTranslations.yourData || 'Your Data'}
                                        </span>
                                    </button>
                                    {isExpanded && (
                                        <div className="inputs-container">
                                            <div className="input-field">
                                                <label htmlFor="name">Name</label>
                                                <input type="text" id="name" name="name" value={userData.name}
                                                       onChange={handleChange} placeholder="Name"/>
                                            </div>
                                            <div className="input-field">
                                                <label htmlFor="email">Email</label>
                                                <input type="email" id="email" name="email" value={userData.email}
                                                       onChange={handleChange} placeholder="Email"/>
                                            </div>
                                            <div className="input-field">
                                                <label htmlFor="surname">Surname</label>
                                                <input type="text" id="surname" name="surname" value={userData.surname}
                                                       onChange={handleChange} placeholder="Surname"/>
                                            </div>
                                            <div className="input-field">
                                                <label htmlFor="bio">Bio</label>
                                                <textarea id="bio" name="bio" value={userData.bio}
                                                          onChange={handleChange} placeholder="Bio"></textarea>
                                            </div>
                                            <button className="save-button" onClick={handleSave} disabled={saving}>
                                                <img src={saveIcon} alt="Save" className="save-icon"/>
                                                <span
                                                    className="button-text">{profileTranslations.saveProfile || ''}</span>
                                            </button>
                                            {saveSuccess && (<div className="save-notification">
                                                    <span className="notification-icon">✔</span> {/* Иконка */}
                                                    <span className="notification-text">Данные успешно сохранены!</span>
                                                </div>
                                            )}</div>
                                    )}
                                </div>
                                <button className="logout-button" onClick={handleLogout}>
                                    <img src={logoutIcon} alt="Logout" className="logout-icon"/>
                                    <span
                                        className="button-text">{profileTranslations.logout || 'Log Out'}
                                </span>
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="profile-input-container">
                                <div className="profile-input-item">
                                    <img src={emailIcon} alt="Email Icon" className="input-icon"/>
                                    <p>{profileTranslations.email || 'Email'}: {userData.email}</p>
                                </div>
                                <div className="profile-input-item">
                                    <img src={surnameIcon} alt="Surname Icon" className="input-icon"/>
                                    <p>{profileTranslations.surname || 'Surname'}: {userData.surname}</p>
                                </div>
                                <div className="profile-input-item">
                                    <img src={bioIcon} alt="Bio Icon" className="input-icon"/>
                                    <p>{profileTranslations.bio || 'Bio'}: {userData.bio || profileTranslations.noBioAvailable || 'No bio available'}</p>
                                </div>
                            </div>


                            <div className="profile-button-container">
                                <div className="profile-actions">
                                    {userData.role !== 'partner' && (
                                        <button className="partner-button" onClick={handleBecomePartner}>
                                            <img src={partnerIcon} alt="Become partner" className="partner-button-icon"/>
                                            <span className="button-form-text">{profileTranslations.becomePartner || 'Become partner'}</span>
                                        </button>
                                    )}
                                </div>
                                {isModalFormOpen && (
                                    <div className="modal-overlay-form">
                                        <div className="modal-content">
                                            <PartnerForm closeModal={closeFormModal}/>
                                            <button className="modal-close-button" onClick={closeFormModal}>❌</button>
                                        </div>
                                    </div>)}


                                {(userData.role === 'partner' || userData.role === 'admin') && (
                                    <button className="crm-button" onClick={handleAddObjects}>
                                        <img src={crmIcon} alt="Crm" className="crm-icon"/>
                                        <span className="button-text">{profileTranslations.crm || 'Add'}</span>
                                    </button>
                                )}
                                {/*{userData.status === 'pending' && (*/}
                                {/*    <p>{profileTranslations.statusPendingPartner || 'Status: Pending Partner'}</p>*/}
                                {/*)}*/}


                                <button className="edit-button" onClick={() => setIsEditing(true)}>
                                    <img src={gearIcon} alt="Edit" className="edit-icon"/>
                                    <span
                                        className="button-text">{profileTranslations.editProfile || 'Edit Profile'}</span>
                                </button>
                                <button
                                    className="support-button-profile"
                                    onClick={() => navigate('/support')}
                                >
                                    <img src={supportIcon} alt="Support" className="support-icon"/>
                                    <span className="button-text">
                                   {profileTranslations.support || 'Support'}
                                    </span>
                                </button>
                                <div className="profile-buttons">
                                    <button className="my-order-profile" onClick={handleButtonClick}>
                                        <img src={ordersIcon} alt="My order" className="order-icon"/>
                                        <span
                                            className="button-text">{profileTranslations.orders || 'My orders'}🔒</span>
                                    </button>
                                    <button className="my-order-profile" onClick={handleButtonClick}>
                                        <img src={likeIcon} alt="My order" className="order-icon"/>
                                        <span
                                            className="button-text">{profileTranslations.liked || 'Liked'}🔒</span>
                                    </button>
                                </div>
                                {/* Модальное окно */}
                                {isModalOpen && (
                                    <div className="modal-overlay">
                                        <div className="modal">
                                            <p className="modal-text">{profileTranslations.modalUnderModeration || 'This feature is under moderation'}</p>
                                            <button className="close-button-modal" onClick={closeModal}>
                                                <span>{profileTranslations.notificationClose}</span>
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
export default Profile;
