import React, { useState, useContext } from 'react';
import './ToggleSwitch.css';
import { LocalizationContext } from '../../Services/localization/LocalizationContext';

const ToggleSwitch = () => {
    const [isRentSelected, setIsRentSelected] = useState(true);
    const { translations } = useContext(LocalizationContext);

    const handleToggle = () => {
        setIsRentSelected(!isRentSelected);
    };

    return (
        <div className="toggle-switch" onClick={handleToggle}>
            <div className={`slider ${isRentSelected ? 'left' : 'right'}`}></div>
            <div className={`option ${isRentSelected ? 'selected' : ''}`}>{translations.toggleSwitch.rent}</div>
            <div className={`option ${!isRentSelected ? 'selected' : ''}`}>{translations.toggleSwitch.purchase}</div>
        </div>
    );
};

export default ToggleSwitch;