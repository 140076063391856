import { useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import './UnavailableCategoryPage.css';
import { LocalizationContext} from "../../Services/localization/LocalizationContext";

const UnavailableCategoryPage = () => {
    const { translations } = useContext(LocalizationContext);
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/become-partner');
    };

    return (
        <div className="unavailable-category-container">
            <h2>{translations.unavailableCategoryPage.title}</h2>
            <p>{translations.unavailableCategoryPage.description}</p>
            <button className="partner-button" onClick={handleButtonClick}>
                {translations.unavailableCategoryPage.becomePartnerButton}
            </button>
        </div>
    );
};

export default UnavailableCategoryPage;
