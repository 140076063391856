export const profileIcon = `
    <svg class="nav-icon-svg" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
      <g clip-path="url(#clip0_1_2768)">
        <path d="M33.4229 29.1666H16.5771C13.8392 29.1699 11.2144 30.259 9.27839 32.195C7.3424 34.131 6.25331 36.7558 6.25 39.4937V50H43.75V39.4937C43.7467 36.7558 42.6576 34.131 40.7216 32.195C38.7856 30.259 36.1608 29.1699 33.4229 29.1666Z"/>
        <path d="M25 25C31.9036 25 37.5 19.4036 37.5 12.5C37.5 5.59644 31.9036 0 25 0C18.0964 0 12.5 5.59644 12.5 12.5C12.5 19.4036 18.0964 25 25 25Z"/>
      </g>
      <defs>
        <clipPath id="clip0_1_2768">
          <rect width="50" height="50"/>
        </clipPath>
      </defs>
    </svg>
`;