import { useState, useContext, useEffect } from "react";
import './SignUpForm.css';
import { LocalizationContext } from "../../Services/localization/LocalizationContext";
import { auth, db, doc, setDoc } from '../../firebase';
import { createUserWithEmailAndPassword, GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";
import { Link, useLocation } from "react-router-dom";
import image1 from '../img/google-light.png';
import eyeOpen from '../img/eyeIconOpen.png'; // Открытый глаз (черно-белый)
import eyeClosed from '../img/eyeIconClosed.png'; // Закрытый глаз (черно-белый)
import lightThemeImage from '../img/apple-light.png'; // Изображение для светлой темы
import darkThemeImage from '../img/apple-dark.png';

const SignUpForm = () => {
    const { translations } = useContext(LocalizationContext);
    const [theme, setTheme] = useState('day-mode');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        const handleThemeChange = (e) => {
            setTheme(e.detail);
        };

        window.addEventListener('themeChange', handleThemeChange);
        return () => {
            window.removeEventListener('themeChange', handleThemeChange);
        };
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        login: '',
        password: '',
        confirmPassword: ''
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });

        let validationErrors = { ...errors };

        if (id === 'confirmPassword' && formData.password !== value) {
            validationErrors.confirmPassword = translations.signUpForm.passwordsDoNotMatch;
        } else if (id === 'email' && !/\S+@\S+\.\S+/.test(value)) {
            validationErrors.email = translations.signUpForm.invalidEmail;
        } else {
            delete validationErrors[id];
        }

        setErrors(validationErrors);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage('');
        setErrors({});

        let validationErrors = {};

        if (formData.password !== formData.confirmPassword) {
            validationErrors.confirmPassword = translations.signUpForm.passwordsDoNotMatch;
        }

        const emailPattern = /\S+@\S+\.\S+/;
        if (!emailPattern.test(formData.email)) {
            validationErrors.email = translations.signUpForm.invalidEmail;
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setLoading(true);

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
            const user = userCredential.user;

            await setDoc(doc(db, "users", user.uid), {
                uid: user.uid,
                name: formData.name,
                surname: formData.surname,
                email: formData.email,
                login: formData.login
            });

            setSuccessMessage("User signed up successfully!");

            setFormData({
                name: '',
                surname: '',
                email: '',
                login: '',
                password: '',
                confirmPassword: ''
            });
        } catch (error) {
            console.error("Error during sign-up: ", error);
            setErrors({ general: error.message });
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleSignUp = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            await setDoc(doc(db, "users", user.uid), {
                uid: user.uid,
                name: user.displayName,
                email: user.email,
                login: user.email // Или любое другое поле, которое вы хотите использовать
            });

            setSuccessMessage("User signed up with Google successfully!");
        } catch (error) {
            console.error("Google sign-up error: ", error);
            setErrorMessage("Failed to sign up with Google. Please try again.");
        }
    };

    const handleAppleSignUp = async () => {
        const provider = new OAuthProvider('apple.com');
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            await setDoc(doc(db, "users", user.uid), {
                uid: user.uid,
                name: user.displayName || formData.name, // или любое другое поле
                email: user.email,
                login: user.email // Или любое другое поле, которое вы хотите использовать
            });

            setSuccessMessage("User signed up with Apple successfully!");
        } catch (error) {
            console.error("Apple sign-up error: ", error);
            setErrorMessage("Failed to sign up with Apple. Please try again.");
        }
    };

    const location = useLocation(); // Для определения текущего маршрута

    return (
        <div className="signup-form-container">
            <div className="signup-form-header">
                <h2>
                    <Link to="/login" className={location.pathname === "/login" ? "active-link" : ""}>
                        {translations.loginForm.loginButton}
                    </Link>
                </h2>

                <h2>
                    <Link to="/signup" className={location.pathname === "/signup" ? "active-link" : ""}>
                        {translations.loginForm.signUpHere}
                    </Link>
                </h2>
            </div>
            <div className="image-form">
                <div className="signup-form-content-image">
                    <div className="signup-form-image"></div>
                </div>
                <div className="signup-form-content">

                    <div className="signup-form-body">
                        <h1>{translations.signUpForm.createAccount}</h1>
                    </div>


                    <div className="signup-form-group">
                        <div className="signup-image-container">
                            <img src={image1} alt="Google icon" className="login-image" onClick={handleGoogleSignUp}/>
                        </div>
                        <div className="signup-image-container">
                            <img
                                src={theme === 'night-mode' ? darkThemeImage : darkThemeImage}
                                alt="Apple icon"
                                className="login-image"
                                onClick={handleAppleSignUp} // Добавьте обработчик для регистрации через Apple
                            />
                        </div>

                    </div>
                    <div className="signup-form-body">
                    <p>{translations.loginForm.emailOrPhoneOrLogin}</p>
                    </div>


                    <form onSubmit={handleSubmit}>
                        <p>{translations.loginForm.loginPrompt}</p>
                        <div className="form-group">
                            <input
                                type="text"
                                id="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                                placeholder={translations.signUpForm.name}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                id="login"
                                value={formData.login}
                                onChange={handleInputChange}
                                required
                                placeholder={translations.signUpForm.login}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="email"
                                id="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                                placeholder={translations.signUpForm.email}
                            />
                            {errors.email && <p className="error-message">{errors.email}</p>}
                        </div>
                        <div className="form-group">
                            <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                required
                                placeholder={translations.loginForm.password}
                            />
                            <span
                                onClick={togglePasswordVisibility}
                                className={`eye-icon ${showPassword ? "open" : "closed"}`}
                            >
                            <img
                                src={showPassword ? eyeOpen : eyeClosed}
                                alt={showPassword ? "Hide password" : "Show password"}
                                className="eye-icon-image"
                            />
                        </span>
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                id="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleInputChange}
                                required
                                placeholder={translations.signUpForm.confirmPassword}
                            />
                            {errors.confirmPassword && <p className="error-message">{errors.confirmPassword}</p>}
                        </div>

                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                        <button type="submit" className="submit-button" disabled={loading}>
                            {loading ? "Signing up..." : translations.loginForm.loginButton}
                        </button>
                    </form>
                </div>
            </div>
        </div>

    );

};

export default SignUpForm;
