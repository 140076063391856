import React, {useContext, useEffect, useState} from 'react';
import {useTelegram} from "../hooks/useTelegram";
import './header.css';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { LocalizationContext} from "../../Services/localization/LocalizationContext";
import { auth, db } from "../../firebase";
import { getDoc, doc } from "firebase/firestore"
import { countries } from "../../Services/constants/countries";
import { languages } from "../../Services/constants/languages";
import logo from '../img/logo.PNG'
import { UserContext } from '../../Services/context/UserContext';
import { defaultUserIcon } from '../img/defaultUserIcon';
import ThemeToggle from "../ThemeToggle/ThemeToggle";
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';

const Header = () => {
    const { user, onClose } = useTelegram();
    const navigate = useNavigate();
    const location = useLocation();
    const { switchLanguage, translations, locale } = useContext(LocalizationContext);
    const { userData } = useContext(UserContext);

    const [userState, setUserState] = useState({
        isAdmin: false,
        currentUser: null,
        profileImage: userData.avatar || null,
        showLanguages: false,
        showCountries: false,
        selectedCountry: countries.find(country => country.name === 'Georgia'),
    });

    const updateUserState = (field, value) => {
        setUserState(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    useEffect(() => {
        updateUserState('profileImage', userData.avatar || null);
    }, [userData]);

    useEffect(() => {
        const fetchUserData = async () => {
            const user = auth.currentUser;
            if (user) {
                updateUserState('currentUser', user);

                const userDoc = await getDoc(doc(db, "users", user.uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    updateUserState('profileImage', userData.avatar || null);
                }
            } else {
                updateUserState('profileImage', null);
            }
        };

        fetchUserData();
    }, []);

    const toggleCountryHandler = () => {
        updateUserState('showCountries', !userState.showCountries);

        const selector = document.querySelector('.country-selector');
        if (selector) {
            selector.classList.toggle('open');
        }

        const button = document.querySelector('.country-button._selector-button');
        if (button) {
            button.classList.toggle('open');
        }
    };

    const handleCountryChange = (selectedCountry) => {
        updateUserState('selectedCountry', selectedCountry);
        updateUserState('showCountries', false);
    };

    const toggleLanguageHandler = () => {
        updateUserState('showLanguages', !userState.showLanguages);

        const switcher = document.querySelector('.language-switcher');
        if (switcher) {
            switcher.classList.toggle('open');
        }
        const button =  document.querySelector('.lang-button._selected');
        if (button) {
            button.classList.toggle('open');
        }
    };

    const handleLanguageChange = (lang) => {
        switchLanguage(lang);
        updateUserState('showLanguages', false);
    };

    const handleProfileClick = () => {
        if (userState.currentUser) {
            navigate('/profile');
        } else {
            navigate('/login');
        }
    };

    const renderProfileImage = () => {
        return userState.profileImage ? (
            <img src={userState.profileImage} alt="User Profile" className="profile-image" />
        ) : (
            <span dangerouslySetInnerHTML={{ __html: defaultUserIcon }} className="default-user-icon"></span>
        );
    };

    return (

        <div className='header'>
            <div className="header-left">
                <Link to="/" className="logo">
                    <img src={logo} alt="Logo" className="logo-image"/>
                </Link>

                {/*{location.pathname !== '/' && (*/}
                {/*    <button className='return_button' onClick={onAddHandLer}>*/}
                {/*        <i className="fas fa-arrow-left"></i> /!* Back icon *!/*/}
                {/*    </button>*/}
                {/*)}*/}
                {/*<span className='username'>*/}
                {/*    {auth.currentUser ? `Your login: @${auth.currentUser.email}` : `Your login: @${user?.username}`}*/}
                {/*</span>*/}

                {/* Country Selector */}

                <div className="country-selector">
                    <button
                        onClick={toggleCountryHandler}
                        className="country-button _selector-button"
                    >
                        <span className={`fi ${userState.selectedCountry.flag} country-flag`}></span>
                        <span className="country-name">{userState.selectedCountry.name}</span>
                        <span className="country-arrow-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="11" viewBox="0 0 20 11"
                                 fill="none">
                              <path d="M1 1.5L10 8.5L19 1.5" stroke="var(--text-color-primary)" strokeWidth="2"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                    </button>

                    {userState.showCountries && (
                        <div className="country-options">
                            {countries
                                .filter(country => country.name !== userState.selectedCountry.name) // Filter out the selected country
                                .map(country => (
                                    <button
                                        key={country.code}
                                        onClick={() => handleCountryChange(country)}
                                        className={`country-button ${country.name !== 'Georgia' ? 'disabled' : ''}`}
                                        disabled={country.name !== 'Georgia'}
                                    >
                                        <span className={`fi ${country.flag} country-flag`}></span>
                                        <span className="country-name">{country.name}</span>
                                    </button>
                                ))
                            }
                        </div>
                    )}
                </div>
            </div>
            <ToggleSwitch />
            <div className="header-right">
                <ThemeToggle />
                <div className="language-switcher">
                    <button
                        onClick={toggleLanguageHandler}
                        className="lang-button _selected"
                    >
                        <span
                            className="lang-name">{locale.charAt(0).toUpperCase() + locale.slice(1).toLowerCase()}</span>
                        <span className="lang-arrow-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="5"
                                 viewBox="0 0 12 5" fill="none">
                                <path d="M1 1L6 4L11 1" stroke="var(--text-color-primary)" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </span>
                    </button>

                    {userState.showLanguages && (
                        <div className="language-options">
                            {languages
                                .filter(language => language.code !== locale)
                                .map(language => (
                                    <button
                                        key={language.code}
                                        onClick={() => handleLanguageChange(language.code)}
                                        className={`lang-button ${locale === language.code ? 'active' : ''}`}
                                >
                                {language.label.charAt(0).toUpperCase() + language.label.slice(1).toLowerCase()}
                                </button>
                            ))}
                        </div>
                    )}
                </div>

                {/*<div className="profile-icon" onClick={handleProfileClick}>*/}
                {/*    {renderProfileImage()}*/}
                {/*</div>*/}
            </div>
        </div>
    );
};
export default Header;