import './CategoryPopup.css';

const CategoryPopup = ({ isOpen, message, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="category-popup-container">
            <div className="category-popup-content">
                <p>{message}</p>
                <button className="category-popup-close-button" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default CategoryPopup;
