import { initializeApp } from "firebase/app";
import { getAuth} from "firebase/auth";
import { getFirestore, collection, addDoc, doc, setDoc, updateDoc, deleteDoc, getDocs, getDoc} from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAvFjYoJFR_ReoZRPZ4LxypvykuLhZTwoI",
    authDomain: "weekend-736ef.firebaseapp.com",
    databaseURL: "https://weekend-736ef-default-rtdb.firebaseio.com",
    projectId: "weekend-736ef",
    storageBucket: "weekend-736ef.appspot.com",
    messagingSenderId: "138327812477",
    appId: "1:138327812477:web:2a5641eacae1245cd68414",
    measurementId: "G-KPG96P9Z3E"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db ,collection, addDoc, auth, doc, setDoc, updateDoc, deleteDoc, getDocs, getDoc, storage };