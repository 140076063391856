import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './NavBar.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { mapIcon } from '../img/mapIcon';
import { homeIcon }  from '../img/homeIcon';
import { categoryIcon } from '../img/categoryIcon';
import ProfileIcon from "../ProfileIcon/ProfileIcon";

const NavBar = () => {
    const [activeButton, setActiveButton] = useState('home'); // Устанавливаем 'home' как активную кнопку по умолчанию

    const handleProfileClick = () => {
        setActiveButton('profile');
    };

    return (

        <div className="nav-bar">
            <NavLink
                to="/map"
                className={({isActive}) => `nav-button ${isActive ? 'active' : ''}`}
                onClick={() => setActiveButton('map')}
            >
                <span dangerouslySetInnerHTML={{__html: mapIcon}}/>
            </NavLink>
            <NavLink
                to="/home"
                className={({isActive}) => `nav-button ${isActive ? 'active' : ''} center`}
                onClick={() => setActiveButton('home')}
            >
                <span dangerouslySetInnerHTML={{__html: homeIcon}}/>
            </NavLink>
            <NavLink
                to="/product-list-cars"
                className={({isActive}) => `nav-button ${isActive ? 'active' : ''}`}
                onClick={() => setActiveButton('category')}
            >
                <span dangerouslySetInnerHTML={{__html: categoryIcon}}/>
            </NavLink>

            <NavLink
                to="/profile"
                className={({isActive}) => `nav-button ${isActive ? 'active' : ''}`}
                onClick={handleProfileClick}
            >
                <ProfileIcon onClick={handleProfileClick}/>
            </NavLink>
        </div>

    );
};

export default NavBar;