import { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { MAPBOX_ACCESS_TOKEN } from '../../config';

const useMap = (mapContainerRef, geocoderContainerRef, icon, mapStyle) => {
    const mapRef = useRef(null);
    const markerRef = useRef(null);

    const add3DBuildingsLayer = (map) => {
        const layers = map.getStyle().layers;
        const labelLayerId = layers.find(
            (layer) => layer.type === 'symbol' && layer.layout['text-field']
        )?.id;

        if (labelLayerId) {
            console.log('Adding 3D buildings layer');
            map.addLayer({
                id: 'add-3d-buildings',
                source: 'composite',
                'source-layer': 'building',
                type: 'fill-extrusion',
                paint: {
                    'fill-extrusion-height': ['get', 'height'],
                    'fill-extrusion-base': ['get', 'min_height'],
                    'fill-extrusion-color': '#aaa',
                    'fill-extrusion-opacity': 0.6,
                },
            }, labelLayerId);
        }
    };

    useEffect(() => {
        // if (mapRef.current) return;
        mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: mapStyle,
            center: [41.62, 41.64],
            zoom: 12,
            pitch: 45,
            bearing: 90.6,
            antialias: true,
            collectResourceTiming: false, // Отключаем сбор событий
        });

        mapRef.current = map;
        map.addControl(new mapboxgl.NavigationControl());

        map.on('style.load', () => {
            add3DBuildingsLayer(map);
            console.log('3D rerender ON LOAD');
        });

        // Add geocoder control
        new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            placeholder: 'Search for a location...',
            marker: true,
            container: geocoderContainerRef.current,
        });

        // Watch user's current location and update the marker in real-time
        const watchId = navigator.geolocation.watchPosition(
            (position) => {
                const { latitude, longitude } = position.coords;

                if (markerRef.current) {
                    // Update marker position
                    markerRef.current.setLngLat([longitude, latitude]);
                } else {
                    // Create a custom icon
                    const customIcon = document.createElement('div');

                    customIcon.className = 'custom-marker';
                    customIcon.innerHTML = `<img class="pointa" src="${icon}" alt="Custom Icon" />`;

                    // Create marker using the custom icon
                    markerRef.current = new mapboxgl.Marker({ element: customIcon })
                        .setLngLat([longitude, latitude])
                        .addTo(map);
                }
            },
            (error) => {
                console.error('Error getting location:', error);
            },
            { enableHighAccuracy: false, timeout: 4000, maximumAge: 0 }
        );

        // Clean up the watch when the component is unmounted
        return () => {
            navigator.geolocation.clearWatch(watchId);
            map.remove();
        };
    }, [mapContainerRef, geocoderContainerRef, icon]); // Dependencies

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setStyle(mapStyle).on('style.load', () => {
                console.log('Style switched to:', mapStyle);
                add3DBuildingsLayer(mapRef.current);
                console.log('3D rerender when mapStyle change');
            }).on('error', (e) => {
                console.error('Error loading new style:', e);
            });
        }
    }, [mapStyle]);

    return { mapRef, markerRef };
};

export default useMap;
