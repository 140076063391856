import React from "react";
import "./AppLoader.css";

const AppLoader = () => {
    return (
        <div className="loader-container">
            <div className="loader-content">





                <img
                    className="app-logo"
                    src="/images/Welcometext1.png" // Укажите правильный путь к вашему логотипу
                    alt="App Logo"
                />


                <img
                    className="loading-gif"
                    src="/images/loading.gif" /* Укажите путь к GIF-файлу */
                    alt="Loading Animation"
                />
                <img
                className="carsbanner1"
                src="/images/carsbanner2.png" /* Укажите путь к GIF-файлу */
                alt="Loading Animation"
            />
                <div className="loading-text-body">

                    {/*<img*/}
                    {/*    className="loading-text"*/}
                    {/*    src="/images/Loading_.png"*/}
                    {/*    alt="Loading text"*/}
                    {/*/>*/}
                    <img
                        className="loading-text2"
                        src="/images/loadingcategory.png"
                        alt="Loading text2"
                    />
                </div>


            </div>
        </div>
    );
};

export default AppLoader;
